import throttle from "lodash.throttle"
import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import usePrevious from "components/utils/hooks/usePrevious"
import useWindowSize from "components/utils/hooks/useWindowSize"
import Layout from "components/layout/layout"
import Hero from "components/hero"
import SEO from "components/seo"
import Row from "components/row"
import { seoDataFromPage } from "components/utils/data-formatting"

import r from "./references.module.scss"

// scroll tolerance in pixels
const SCROLL_TOLERANCE = 10

const References = ({ pageContext, data: queryData }) => {
  const { width } = useWindowSize()
  const renderMobile = width <= 860
  const [activeItemIdx, setActiveItemIdx] = useState(0)
  const [scrollBtnDisabled, setScrollBtnDisabled] = useState(false)
  const [scrollBtnReverse, setScrollBtnReverse] = useState(false)
  const prevActiveItemIdx = usePrevious(activeItemIdx)
  const accordionRef = useRef(null)
  const accordionContentRef = useRef(null)

  useEffect(() => {
    const accordion = accordionRef.current
    const item = accordion.children[activeItemIdx]
    item.style.height = `${item.scrollHeight}px`

    if (
      prevActiveItemIdx !== undefined &&
      activeItemIdx !== prevActiveItemIdx
    ) {
      const prevItem = accordion.children[prevActiveItemIdx]
      const header = prevItem.querySelector(`.${r.accordion_item_header}`)
      const headerHeight = header.getBoundingClientRect().height
      prevItem.style.height = `calc(${headerHeight}px + 2rem)`
    }
  }, [activeItemIdx, prevActiveItemIdx])

  useEffect(() => {
    if (renderMobile) return

    // set scroll button disabled state
    const el = accordionContentRef.current
    const scrollNeeded = el.scrollHeight - el.getBoundingClientRect().height
    // disable scroll button if everything is in view already
    if (scrollNeeded < SCROLL_TOLERANCE) {
      setScrollBtnDisabled(true)
    } else {
      setScrollBtnDisabled(false)
    }
  }, [activeItemIdx])

  useEffect(() => {
    if (renderMobile) return

    const el = accordionContentRef.current
    const scrollNeeded = el.scrollHeight - el.getBoundingClientRect().height

    if (!scrollBtnDisabled) {
      const handleScroll = throttle(e => {
        const { scrollTop } = e.target
        // with 5px playroom
        if (scrollTop + SCROLL_TOLERANCE >= scrollNeeded) {
          setScrollBtnReverse(true)
        } else {
          setScrollBtnReverse(false)
        }
      }, 100)

      el.addEventListener("scroll", handleScroll)

      return () => {
        el.removeEventListener("scroll", handleScroll)
      }
    }
  }, [activeItemIdx, scrollBtnDisabled])

  const scrollContent = () => {
    const el = accordionContentRef.current
    const elHeight = el.getBoundingClientRect().height
    const scrollNeeded = el.scrollHeight - elHeight
    let top = scrollNeeded
    // scrollTop is distance to the top we have scrolled.
    // If we are at the bottom, it is approximately (scrollNeeded) el.scrollHeight - elHeight
    if (el.scrollTop + SCROLL_TOLERANCE >= scrollNeeded) {
      top = -el.scrollTop
    }
    el.scrollBy({
      top,
      left: 0,
      behavior: "smooth",
    })
  }

  const { title } = pageContext
  const { acf } = queryData.wordpressPage
  const { hero, reviews } = acf.references

  const meta = seoDataFromPage(queryData.wordpressPage)

  return (
    <Layout>
      <SEO title={title} meta={meta} />
      <Hero data={hero} />
      <Row
        id={title}
        customClass={r.references_page_block}
        bgColor="anthracite"
      >
        <div className={r.accordionContainer}>
          <div className={r.accordion} ref={accordionRef}>
            {reviews.map((review, index) => (
              <div
                className={[
                  r.accordion_item,
                  index === activeItemIdx && r.active,
                ].join(" ")}
                key={`item_${index}`}
                onClick={() => setActiveItemIdx(index)}
              >
                <div className={r.accordion_item_header}>{review.company}</div>
                <div className={r.accordion_item_subheader}>
                  {review.person && (
                    <div className={r.accordion_item_subheader_person}>
                      {review.person}
                    </div>
                  )}
                  {review.website && (
                    <a
                      href={review.website}
                      target="_blank"
                      className={r.accordion_item_subheader_website}
                      rel="noopener noreferrer"
                    >
                      Website
                    </a>
                  )}
                </div>
                {renderMobile && (
                  <div className={r.accordion_item_mobile_content}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: reviews[activeItemIdx].content,
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          {!renderMobile && (
            <div className={r.accordionContent}>
              <div
                ref={accordionContentRef}
                className={r.accordionContent_text}
                key={`content_${activeItemIdx}`}
                dangerouslySetInnerHTML={{
                  __html: reviews[activeItemIdx].content,
                }}
              />
              <div className={r.overlayScrollBtn}>
                <div
                  onClick={scrollContent}
                  className={[
                    r.overlayScrollBtn_icon,
                    scrollBtnReverse && r.reverse,
                    scrollBtnDisabled && r.disabled,
                  ].join(" ")}
                ></div>
              </div>
            </div>
          )}
        </div>
      </Row>
    </Layout>
  )
}

export default References

// convert static query above to page query
export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      acf {
        references {
          hero {
            title
            text
            button {
              btn_label
              btn_link
            }
            bg {
              ...ImageFullWidth
            }
          }
          reviews {
            company
            person
            website
            content
          }
        }
      }
      ...SEOData
    }
  }
`
