import React from "react"

import Row from "./row"
import Button from "./button"
import Image from "safe-gatsby-image-plugin"

import h from "./hero.module.scss"
import l from "./layout/layout.module.scss"

const Hero = ({ data, extraClass = null }) => {
  return (
    <section className={[h.hero, extraClass].join(" ")}>
      <Image
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 0,
          height: "100%",
        }}
        data={data.bg}
      />
      <Row customClass={h.content}>
        <h1 className={h.title}>{data.title}</h1>
        <p className={l.text_under_heading}>{data.text}</p>
        {data.button && <Button data={data.button} />}
      </Row>
    </section>
  )
}

export default Hero
